<template>
  <div :class="$style.wrapper">
    <NuxtPage />
  </div>
</template>

<style lang="scss" module>
  .wrapper {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    min-height: 100vh;
  }
</style>